"use strict";

export function init() {
    // wpo skip link test (skip links are not allowed to be a-tag
    let $skiplinks = $('.js-skip-links');

    $skiplinks.on('click', function () {
        let $target = $($(this).attr('data-href'));
        window.scrollTo(window.scrollX, $target[0].offsetTop);
        $target.focus();
    });
}