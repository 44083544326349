"use strict";

export function initInScope($scope) {
    $('.js-hotspots').each(function () {
        let $container = $(this);
        let $details = $container.find('.js-hotspots__detail');
        let $toggles = $container.find('.js-hotspots__toggle');

        $toggles.on('click', function () {
            let $this = $(this);

            if ($this.hasClass('is-open')) {
                $toggles.removeClass('is-open');
                closeAll();
            } else {
                setActiveHotspotById($this.data('hotspots-id'));
            }
        });

        $container.find('.js-hotspots__close').on('click', closeAll);

        $container.find('.js-hotspots__go-to').on('click', function () {
            setActiveHotspotById($(this).data('hotspots-id'));
        });

        function setActiveHotspotById(id = null) {
            $container.removeClass('is-open');

            $details.removeClass('is-open')
                .attr('aria-hidden', 'false');

            $toggles.removeClass('is-open')
                .attr('aria-expanded', 'false');

            if (id !== null) {
                // open
                $container.addClass('is-open');

                $details
                    .filter(`[data-hotspots-id="${ id }"]`)
                    .addClass('is-open')
                    .attr('aria-hidden', 'true');

                $toggles
                    .filter(`[data-hotspots-id="${ id }"]`)
                    .addClass('is-open')
                    .attr('aria-expanded', 'true');
            }
        }

        function closeAll() {
            setActiveHotspotById(null);
        }
    });
}

