"use strict";

export function initInScope($scope) {
    let $toggler = $scope.find('.js-portal-switch__toggle');
    let $items = $scope.find('.js-portal-switch__item');

    $toggler.on('click', function () {
        let $this = $(this);
        let portal = $this.data('portal-switch');
        let $item = $scope.find(`.js-portal-switch__item[data-portal-switch='${portal}']`);
        $toggler.removeClass('active');
        $this.addClass('active');
        $items.removeClass('active');
        $item.addClass('active');
    });
}
