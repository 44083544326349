"use strict";

import $ from 'jquery';
import loadJQueryUi from '@elements/load-jquery-ui';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";

function addKeyboardSupport($datepicker, $altField) {
    let newDate = localDateToUTCDate($datepicker.datepicker('getDate'));

    $datepicker.on('keydown', function (evt) {
        if(newDate == null) {
            $datepicker.datepicker('setDate', new Date());
            newDate = $datepicker.datepicker('getDate');
        }

        switch (evt.key) {
            case 'ArrowLeft':
                newDate.setDate(newDate.getDate() - 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowUp':
                newDate.setDate(newDate.getDate() - 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowRight':
                newDate.setDate(newDate.getDate() + 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowDown':
                newDate.setDate(newDate.getDate() + 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageUp':
                newDate.setDate(newDate.getDate() - 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageDown':
                newDate.setDate(newDate.getDate() + 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'Tab':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');
                break;
            case 'Enter':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');

                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
        }

        if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
            $datepicker.datepicker('setDate', newDate);
        }
    });
}

// returns UTC Date
export function getDate($datepicker) {
    return ISOStringToDate($datepicker.find('.js-datepicker__alt-field').val());
}

// date: UTC DATE
export function setDate($datepicker, date) {
    loadJQueryUi().then(function () {
        $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
        $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
    });

}

// date: UTC DATE
export function setInitialMinDate($datepicker, date) {
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-datepicker__input').val() && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() < date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'minDate', UTCDateToLocalDate(date));
    });
}

export function setMinDate($datepicker, date) {
    // todo do not use get time but the alt field
    if ($datepicker.find('.js-datepicker__input').val() && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() < date.getTime()) {
        $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
        $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
    }

    $datepicker.find('.js-datepicker__input')
        .datepicker('option', 'minDate', UTCDateToLocalDate(date));
}

// date: UTC DATE
export function setMaxDate($datepicker, date) {
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-datepicker__input').val() && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() > date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'maxDate', UTCDateToLocalDate(date));
    });
}

export function createInitInScope(selectors, defaultOptions) {
    return function ($scope) {
        let $datepickerContainers = $scope.find(selectors.base);
        loadJQueryUi().then(function () {
            $datepickerContainers.each(function () {
                let $container = $(this);
                let $datepicker = $container.find(selectors.input);
                let $altField = $container.find(selectors.altField);

                let options = {
                    numberOfMonths: 1,
                    minDate: UTCDateToLocalDate(new Date()),
                    nextText: '<span class="icon icon-arrow"></span>',
                    prevText: '<span class="icon icon-arrow"></span>',
                    firstDay: 1,
                    showAnim: 'show', // other animations (like fadeIn) do not work with jquery.slim
                    beforeShowDay: function(date) {
                        let cssClasses = [];

                        if($container.closest('.js-datepicker-range').length > 0) {
                            let $wrapper = $container.closest('.js-datepicker-range');
                            let from = getDate($wrapper.find('.js-datepicker-range__from'));
                            let to = getDate($wrapper.find('.js-datepicker-range__to'));

                            if(typeof from !== "undefined" && typeof to !== "undefined") {
                                // is in between
                                if (date.getTime() > from.getTime() && date.getTime() < to.getTime()) {
                                    cssClasses.push('is-in-range');
                                }
                            }
                            
                            if(typeof from !== "undefined") {
                                // is arrival
                                if (localDateToUTCDate(date).getTime() === from.getTime()) {
                                    cssClasses.push('is-arrival');
                                }
                            }
                            if(typeof to !== "undefined") {
                                // is departure
                                if (localDateToUTCDate(date).getTime() === to.getTime()) {
                                    cssClasses.push('is-departure');
                                }
                            }
                        }

                        return [true, cssClasses.join(' ')];
                    },
                    onSelect: function(dateString, inst) {
                        let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                        $altField.val(dateToISOString(selectedDate)).trigger('change');
                    },
                    ...defaultOptions,
                    ...transformDataOptions(getPrefixedDataSet('datepicker', $container))
                };

                addKeyboardSupport($datepicker, $altField);
                $datepicker.datepicker(options);

                if ($altField.val()) {
                    setDate($container, ISOStringToDate($altField.val()));
                }

                $altField.on('change', () => {
                    setDate($container, ISOStringToDate($altField.val()));
                });
            });
        });
    }
}

export const initInScope = createInitInScope({
    base: '.js-datepicker',
    input: '.js-datepicker__input',
    altField: '.js-datepicker__alt-field'
});

function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}
