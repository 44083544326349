"use strict";

export function initInScope ($scope) {
    $scope.find('.js-fancy-checkbox').each(function () {
        let input = $(this).find('input');
        $(this).on('click', function(){
            if(input[0].checked){
                $(this).addClass('is-checked');
                input.attr('checked','checked');
            }
            else{
                $(this).removeClass('is-checked');
                input.removeAttr('checked');
            }
        })
    });

    $scope.find('.js-fancy-checkbox__interests-container').each(function () {
        let $inputs = $(this).find('.js-fancy-checkbox__input');
        let $consentInput = $(this).find('.js-fancy-checkbox__interests-require');
        $inputs.on('change', function(){
            let inputsChecked = false;
            $inputs.each(function () {
                let input = $(this);
                if (input[0].checked){
                    inputsChecked = true;
                }
            });
            if(inputsChecked === true) {
                $consentInput.attr('required', true);
            }
            else{
                $consentInput.removeAttr('required', false);
            }
        });
    });

    $scope.find('.js-fancy-checkbox__reset').on('click', function () {
        $scope.find('.js-fancy-checkbox__interests-require').removeAttr('required');
        $scope.find('.js-fancy-checkbox').each(function () {
            $(this).removeClass('is-checked');
            $(this).find('input').removeAttr('checked');
        });
    });

}