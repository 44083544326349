"use strict";

export function initInScope($scope) {
    console.log("region map");
    import('./svg-converter').then(function () {
        let $regionMapHoverLink = $scope.find('.js-region-map-hover__link'),
            $regionMapHoverMap = $scope.find('.js-region-map-hover__map');

        $regionMapHoverLink.on('mouseover', function () {
            let hoverTarget = $(this).data('hover-target');
            $regionMapHoverMap.find('[data-name=' + hoverTarget + ']').addClass('region-map--hover');
        });

        $regionMapHoverLink.on('mouseout', function () {
            $regionMapHoverMap.find('.region-map--hover').removeClass('region-map--hover');
        });

        $scope.find('.region-map__item').on('mouseover', function () {
            let $regionMapHoverLinkWrapper = $scope.find('.js-region-map-hover__link-wrapper'),
                hoverTarget = $(this).data('name');
            $regionMapHoverLinkWrapper.find('[data-hover-target=' + hoverTarget + ']').addClass('active');
        });

        $scope.find('.region-map__item').on('mouseout', function () {
            let $regionMapHoverLinkWrapper = $scope.find('.js-region-map-hover__link-wrapper');
            $regionMapHoverLinkWrapper.find('.active').removeClass('active');
        });
    });
}
