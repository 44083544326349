"use strict";


export function init () {
    if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches) {
        console.log("matched");
        return;
    }
    
    console.log("animation"); 

    let mouseX, mouseY;
    let $wrapper = document.querySelector('.js-triangle-animation__wrapper');
    let $items = $('.js-triangle-animation__item');
    let bgFollowX = 0,
        bgFollowY = 0,
        moveX = 0,
        moveY = 0,
        friction = 1 / 30;

    function executeFrame() {
        requestAnimationFrame(executeFrame);
        followMouse();
        moveBackground();
    }

    function moveBackground() {
        moveX += (bgFollowX - moveX) * friction;
        moveY += (bgFollowY - moveY) * friction;
        let translate = 'translate(' + moveX + 'px, ' + moveY + 'px) scale(1.1)';
        $('.hero__bg-wrapper').css({
            'transform': translate
        });
    }

    function followMouse() {
        $items.each(function() {
            let rotationValue = mouseX / 15;
            let translateValue = mouseY/13;
            let scaleValue = mouseY/1000 + 0.9;
            this.style.transform = "rotate("  + rotationValue + "deg) translateY("+ translateValue +"px) scale("+ scaleValue +")";
        })
    }

    $wrapper.addEventListener("mousemove", function (evt) {
        mouseX = evt.clientX/window.innerWidth  * 100 - 50;
        mouseY = evt.clientY/window.innerHeight  * 100;

        let bgMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - evt.clientX));
        let bgMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - evt.clientY));
        
        bgFollowX = (20 * bgMouseX) / 100;
        bgFollowY = (10 * bgMouseY) / 100;
    });
    
    executeFrame();

}