"use strict";

export function initInScope ($scope) {

    let $rangeSlider = $scope.find('.js-range-slider');

    import('nouislider').then(function (noUiSlider) {
        $rangeSlider.each(function () {
            let $this = $(this),
                slider  = $this.find('.js-range-slider__slider')[0],

                minValue = $this.data('min-value') || 0,
                maxValue = $this.data('max-value') || 100,

                $minInput = $this.find('.js-range-slider__min'),
                $maxInput = $this.find('.js-range-slider__max'),

                // container where the values get pushed
                $minValueText = $this.find('.js-range-slider__val-min'),
                $maxValueText = $this.find('.js-range-slider__val-max'),

                decimalPlaces = 0,

                prefix = $this.data('prefix') || '',
                suffix = $this.data('suffix') || '',
                maxReachedPrefix = $this.data('max-reached-prefix') || '',

                startMin = minValue,
                startMax = maxValue;


            if ($minInput.val()) {
                startMin = $minInput.val();
            } else {
                startMin = minValue;
            }
            if ($maxInput.val()) {
                startMax = $maxInput.val();
            } else {
                startMax = maxValue;
            }

            noUiSlider.create(slider, {
                range: {
                    'min': [minValue],
                    'max': [maxValue]
                }
                ,start: [startMin, startMax]
                ,handles: 3
                ,step: 1
                ,connect: true
            });

            slider.noUiSlider.on('update', function( values, handle ) {

                let value = +values[handle];

                if ( handle === 0 ) {
                    $minValueText.text(prefix + value.toFixed(decimalPlaces) + suffix);

                    if(parseInt(value.toFixed(decimalPlaces)) === startMin) {
                        $minInput.val('');
                    } else {
                        $minInput.val(value.toFixed(decimalPlaces));
                    }
                } else {
                    $maxValueText.text(prefix + value.toFixed(decimalPlaces) + suffix);

                    if(parseInt(value.toFixed(decimalPlaces)) === startMax) {
                        $maxInput.val('');
                    } else {
                        $maxInput.val(value.toFixed(decimalPlaces));
                        if(parseInt(value.toFixed(decimalPlaces)) === maxValue) {
                            $maxValueText.text(maxReachedPrefix + prefix + value.toFixed(decimalPlaces) + suffix);
                        }
                    }
                }
            });
        });
    });
}

