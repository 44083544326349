"use strict";

import loadScript from '@elements/load-script';
let promise;

export default function loadMapsAPI() {
    if (promise) {
        return promise;
    }

    promise = new Promise((resolve, reject) => {
        if (!_config.outdoorKey) {
            console.warn('Could not load outdoor map because _cfg.outdoorKey was not set.');
            _config.outdoorKey = 'yourtest-outdoora-ctiveapi'; /*dev test key*/
        }

        window.mapApiLoadedCallback = function () {
            resolve();
        };

        loadScript('https://www.outdooractive.com/alpportal/oax_head.js?proj='+ _config.projectKey +'&key=' + _config.outdoorKey + '&callback=window.mapApiLoadedCallback&use_gmap=0&leaflet_gshim=1&lang=' + _config.lang + '&');
    });

    return promise;

}
