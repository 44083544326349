"use strict";

export function initInScope ($scope) {
    let $darkpage = $scope.find('.js-darkpage');

    try {
        let data = sessionStorage.getItem('darkpage');
        if (data != 'alreadyShown') {
            $darkpage.removeAttr('hidden');
            $('body').addClass('overflow-hidden');
        }
    } catch(err) {}

    $('.js-darkpage__close, .js-darkpage__link').on('click', function() {
        try {
            sessionStorage.setItem('darkpage', 'alreadyShown');
            $darkpage.attr('hidden', '');
            $('body').removeClass('overflow-hidden');

        } catch(err) {}
    });
}
