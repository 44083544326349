"use strict";

//import {isParsleyForm, isValid} from "./parsley";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    $('.js-ajax-form-upload').each(function () {
        let $container = $(this);
        let $result = $container.find('.js-ajax-form-upload__result');
        let $loading = $container.find('.js-ajax-form-upload__loading');
        let $error = $container.find('.js-ajax-form-upload__error');
        let pendingRequest = null;
        let $submitOnChange = $container.data('trigger') || false;
        let $form = $container.find('.js-ajax-form-upload__form');

        if($submitOnChange) {
            $form.on('change', function(evt) {
                $form.submit();
            });
        }


        $form.on('submit', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();

            let $form = $(this);
            if (pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }
            //if (isParsleyForm($form) && !isValid($form)) {
            //    return;
            //}

            $result.attr('hidden', null);
            $loading.attr('hidden', null);

            if($form.data('ajax-form-close-modal') && $form.closest('.modal').length > 0) {
                $form.closest('.modal').modal('hide');
            }

            let formData = new FormData($form[0]) || $form.serializeArray();
            let type = $form.attr('method') || 'POST';

            let action = $form.data('action') || $form.attr('action');
            pendingRequest = $.ajax(action, {
                data: formData,
                dataType: 'JSON',
                method: type,
                processData:false,
                contentType:false
            }).done(function (result) {
                $result.empty().append(result.html);
                initModulesInScope($result);
            }).fail(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    $error.attr('hidden', null);
                    console.error(textStatus, errorThrown, jqXHR);
                }
            }).always(function () {
                $loading.attr('hidden', 'hidden');
                pendingRequest = null;
            })
        });
    })
}
