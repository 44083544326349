"use strict";

export function initInScope ($scope) {
    $scope.find('.js-img-upload').each(function () {

        let $imgUpload = $(this);

        function readURL(input) {
            if (input.files && input.files[0]) {
                let reader = new FileReader();

                reader.onload = function(e) {
                    $imgUpload.closest('.js-img-preview').css('background-image', "url('"+ e.target.result +"')");
                }

                reader.readAsDataURL(input.files[0]);
            }
        }

        $imgUpload.change(function() {
            readURL(this);
        });

    });


}