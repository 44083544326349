"use strict";


export function initInScope($scope) {
    let $sliders = $scope.find('.js-drag-teaser-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {

            let $slider = $(this);

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:false,
                slidesToShow: 1.2,
                slidesPerRow: 2,
                fade: false,
                swipeToSlide: true,
                infinite:false,
                rows: 2,
                speed:300,
                responsive: [
                    {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1.2,
                        slidesPerRow: 1,
                        rows: 2
                    }
                }]
            });

        });
    })
}