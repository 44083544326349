"use strict";


export function initInScope($scope) {
    let $sliders = $scope.find('.js-card-teaser-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {

            let $slider = $(this);
            let $sliderWrapper = $slider.closest('.card-teaser-slider__wrapper');

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:true,
                slidesToShow: 1,
                fade:true,
                swipeToSlide: true,
                infinite:false,
                speed: 1,
                cssEase: 'ease',
                prevArrow: $sliderWrapper.find('.slider-arrow--outlines.arrow-prev'),
                nextArrow: $sliderWrapper.find('.slider-arrow--outlines.arrow-next'),
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        rtl: true,
                        arrows: false
                    }
                }]
            });

        });
    })
}