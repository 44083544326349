"use strict";

export function initInScope ($scope) {
    $scope.find('.js-file-input').on('change', ':file', function () {
        let input = $(this),
            numFiles = input.get(0).files ? input.get(0).files.length : 1,
            label = input.val().replace(/\\/g, '/').replace(/.*\//, '');

        let $label = $(this).closest('.js-file-input').find('.js-file-input__text');
        let labelText = label;

        if (numFiles === 0) {
            labelText = $label.data('file-input-default-text');
        } else if (numFiles > 1) {
            labelText = numFiles + ' ' + $label.data('file-input-multiple-text');
        }

        $label.text(labelText);
    });
}
