"use strict";

export function initInScope($scope) {
    let $forms = $scope.find('.js-step-form');

    $forms.each(function () {
        let $form = $(this);
        let $steps = $form.find('.js-step-form__step');
        let $legendItems = $form.find('.js-step-form__legend-item');

        $steps.each(function (index) {
            $(this).find(':input').attr('data-parsley-group', 'block-' + index);
        });

        navigateTo(0); // Start at the beginning


        $form.find('.js-step-form__next').on('click', function (evt) {
            evt.preventDefault();

            $form.parsley().whenValidate({
                group: 'block-' + curIndex()
            }).done(function () {
                navigateTo(curIndex() + 1);
            });
        });

        $form.find('.js-step-form__prev').click(function (evt) {
            evt.preventDefault();
            navigateTo(curIndex() - 1);
        });

        function curIndex() {
            return $steps.index($steps.filter('.is-current'));
        }

        function navigateTo(index) {
            $steps.removeClass('is-current').eq(index).addClass('is-current');
            $legendItems.removeClass('is-current').eq(index).addClass('is-current');
            let $nextBtn = $form.find('.js-step-form__next');
            let $submitBtn = $form.find('.js-step-form__submit');
            let $cancelBtn = $form.find('.js-step-form__cancel');

            if (index >= $steps.length - 1) {
                $nextBtn.attr('hidden', true);
                $cancelBtn.attr('hidden', true);
                $submitBtn.attr('hidden', false);
            } else {
                $nextBtn.attr('hidden', false);
                $submitBtn.attr('hidden', true);
            }
        }
    });

}
