"use strict";

// these are the elements that triggered a overlay to open.
// we have to return the focus to them after their overlay is closed again.
let toggleByOverlayId = {};
const startZIndex = 100;
let openModals = [];
let $modalsById = {};

export function init() {
    document.body.addEventListener('click', function (evt) {
        if (openModals && openModals.length) {
            let $target = $(evt.target);
            let $parent = $target.closest('.js-overlay, .js-overlay__toggle, .js-overlay__close, #ui-datepicker-div');

            if (!$target.is('.js-overlay, .js-overlay__toggle, .js-overlay__close') && !$parent.length) {
                openModals.map(id => $('#' + id)).forEach(closeOverlay);
            }
        }
    }, true);
}

export function initInScope ($scope) {
    $scope.find('.js-overlay__toggle').on('click', function (evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();


        let $toggle = $(this);
        let $target = $($toggle.data('target'));

        if (!$toggle.data('target')) {
            console.error('Can\'t show overlay. Attribute data-target is not set for', this);
            return;
        }

        if (!$target || !$target.length) {
            console.error('Can\'t show overlay. Could not find overlay "' + $toggle.data('target') + '" for',
                this);
            return;
        }

        let id = $target.attr('id');
        if (!$target.attr('id')) {
            console.warn('Overlay ', $target[0], 'does not have a id attribute. Please add a unique id.')
        }

        if (!$target.hasClass('is-open')) {
            closeOverlay($('.js-overlay'));
            openOverlay($target);
            toggleByOverlayId[id] = $toggle;
        } else {
            closeOverlay($target);
            toggleByOverlayId[id] = null;
        }
    });

    $scope.find('.js-overlay__close').on('click', function (evt) {
        let $modal = $(this).closest('.js-overlay');
        $modal.triggerHandler('overlay.cancel');

        if (!$modal || !$modal.length) {
            console.error('Can\'t closed modal. The close button', this, 'is not a children of a .js-overlay')
            return;
        }

        closeOverlay($modal);
    });

    $scope.find('.js-overlay').each(function () {
        let paramResult = getIdAndElementFromParam($(this));
        if (!paramResult) {return;}

        $modalsById[paramResult.id] = paramResult.$element;
    });
}

export function openOverlay (param) {
    let paramResult = getIdAndElementFromParam(param);
    if (!paramResult) {return;}

    openModals.push(paramResult.id);
    handleChange();
}

export function closeOverlay (param) {
    let paramResult = getIdAndElementFromParam(param);
    if (!paramResult) {return;}

    let id = paramResult.id;

    if (toggleByOverlayId[id]) {
        toggleByOverlayId[id].focus();
        toggleByOverlayId[id] = null;
    }

    openModals.splice(openModals.indexOf(id),1);
    handleChange();
}

function handleChange() {
    let currentZIndex = startZIndex;

    let closedModalIds = Object.keys($modalsById).filter(function (modalId) {
        return openModals.indexOf(modalId) < 0;
    });

    closedModalIds.forEach(function (modalId) {
        let $modal = $modalsById[modalId];
        if ($modal
            && $modal.length
            && $modal.hasClass('is-open')) {
            $modal.one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (evt) {
                $modal.triggerHandler('overlay.hidden');
                $('body').removeClass('overflow-hidden');
            });

            $modal.triggerHandler('overlay.hide');
            $modal.removeClass('is-open').attr('aria-hidden', 'true').css('z-index', '');
        }
    });

    openModals.forEach(function (modalId) {
        let $modal = $modalsById[modalId];

        if ($modal
            && $modal.length
            && !$modal.hasClass('is-open')) {
            $modal.one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function animationEndHandler(evt) {
                if (evt.target === $modal[0]) {
                    $modal.triggerHandler('overlay.shown');
                    $('body').addClass('overflow-hidden');
                }
                $modal.off('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', animationEndHandler);
            });

            $modal.triggerHandler('overlay.show');
            let defaultZIndex = $modal.css('z-index');
            if (!defaultZIndex || defaultZIndex === 'auto') {
                $modal.css('z-index', currentZIndex++);
            }

            $modal
                .addClass('is-open')
                .attr('aria-hidden', 'false')
                .focus();
        }
    });
}

function getIdAndElementFromParam(param) {
    let id;
    let $modal;
    if (typeof param === "string") {
        id = param;
        $modal = $('#' + id + '.js-overlay');
        if (!$modal || !$modal.length) {
            console.error('Can\'t find overlay with id "' + id + '"', $modal);
            return;
        }
    } else {
        $modal = param;
        id = $modal.attr('id');
        if (!id) {
            console.error('Can\'t use overlay ', $modal, '. The overlay needs a unique id attribute');
            return;
        }
    }

    return {
        id: id,
        $element: $modal
    }
}

export function isOverlay ($element) {
    return $element.is('.js-overlay');
}