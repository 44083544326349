const defaultSelectors = {
    base: '.js-tour-consent-overlay__wrapper',
};

export function initInScope($scope) {
    console.log('init')
    let $overlayWrapper =  $scope.find(defaultSelectors.base);
    if ($overlayWrapper.length > 0) {
        let $button = $overlayWrapper.find('.js-consent__accept-btn');
        console.log('button', $button)
        $button.on('click', function (evt) {
            evt.preventDefault();
            console.log('button is clicked');
            Cookiebot.submitCustomConsent(true, true, true);
            setTimeout(function () {
                console.log('page is reloaded');
                window.location.reload();
            }, 200);
        });
    }

    window.addEventListener('CookiebotOnAccept', function (e) {
        let loaded = localStorage.getItem('cookieconsent_laoded');
        localStorage.setItem('cookieconsent_laoded', true);
        let accept = localStorage.getItem('cookieconsent_accepted');
        if(!loaded) {
            if (Cookiebot.consent.marketing & Cookiebot.consent.preferences && Cookiebot.consent.statistics)
            {
                setTimeout(function () {
                    console.log('page is reloaded on change');
                    window.location.reload();
                    localStorage.setItem('cookieconsent_accept', true);
                }, 200);
            }else{
                localStorage.setItem('cookieconsent_accept', false);
                localStorage.removeItem('cookieconsent_laoded');
            }
        }
    }, false);
}