"use strict";

import $ from "jquery";
import initModulesInScope from '@elements/init-modules-in-scope';

const defaultSelectors = {
    base: '.js-touch-nav',
    item: '.js-touch-nav__item'
};

export function createInitInScope(selectors = defaultSelectors) {

    if(matchMedia('(max-width: 767px)').matches){
        /* not on mobile */
        return;
    }

    return function ($scope) {
        return $scope.find(selectors.base).each(function () {
            let $touchNav = $(this);
            let $touchNavItems = $touchNav.find(selectors.item);
            let $touchNavItemsLinks = $($touchNavItems.children());

            $touchNavItemsLinks.on('touchend', function (evt) {
                if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {
                    if (!$(this).closest(defaultSelectors.item).hasClass('is-open')) {
                        $(this).closest(defaultSelectors.base).find('li').removeClass('is-open');
                        $(this).closest(defaultSelectors.item).addClass('is-open');

                        evt.preventDefault();
                        evt.stopImmediatePropagation();

                        $('body').on('click', bodyClickHandler);
                    }

                    let $renderNavbarContainer = $('.js-render-navbar-template');

                    if($renderNavbarContainer.length){

                        $renderNavbarContainer.each(function (index, element) {
                            let $template = $(this).find('.js-render-navbar-template__template');
                            let templateHtml = $template.html();

                            $(this).html(templateHtml).removeClass('js-render-navbar-template');

                            $template.remove();
                            initModulesInScope($(this));
                        });

                    }
                }
            });
        });
    }
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents(defaultSelectors.base).length < 1) {
        evt.preventDefault();
        $(defaultSelectors.item).removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}

export const initInScope = createInitInScope();