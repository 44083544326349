"use strict";


let sliderId = 0;

export function initInScope($scope) {
    let $slider = $scope.find('.js-product-thumb-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-product-thumb-slider__main');
            let $thumbSlider = $container.find('.js-product-thumb-slider__thumbs');

            $mainSlider.slick({
                asNavFor: $thumbSlider,
                dots: true,
                arrows: false,
                slidesToShow: 1,
                infinite: false,
                fade: true,
                speed: 600,
            });

            if ($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    variableWidth: false,
                    swipeToSlide: true,
                    asNavFor: $mainSlider,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 4.2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2.2
                            }
                        }
                    ]
                })
            }

            $mainSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                let sliderHeight = $mainSlider.find('.slick-slide').eq(nextSlide).children().first().height();
                $mainSlider.find('.slick-slide').height(sliderHeight);
            });
        });
    });
}

