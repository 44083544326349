"use strict";
import {isElementInViewport} from '@elements/viewport-utils';
import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches) {
        console.log("matched");
        return;
    }
    
    add(
        $scope.find('.js-triangle-scroll-animation'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {

    let start;

    if(isElementInViewport (element)) {
        start =  window.innerHeight - element.getBoundingClientRect().bottom / 3;
    } else {
        start = window.innerHeight / 10 * 8;
    }

    const end = -300;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    let parallaxValue = $(element).data('animation-value') || 20;
    let scaleValue = $(element).data('animation-scale-value');
    let rotateValue = $(element).data('animation-rotate-value');
    let translateXValue = $(element).data('animation-translatex-value');
    let opacity = $(element).data('animation-change-opacity') || false;

    let progressTranslateY = progress * parallaxValue;
    let progressTranslateX = translateXValue ? progress * translateXValue : 0;
    let progressScale = scaleValue ? 1 - progress*scaleValue/10 : 1;
    let progressRotate = rotateValue ? progress*rotateValue : 0;

    element.style.transform = "translateY(" + progressTranslateY + "px) translateX(" + progressTranslateX + "px) rotate("  + progressRotate + "deg)  scale("  + progressScale + ")";
    if(opacity) {
        element.style.opacity = 1 - progress + 0.2;
    }
}