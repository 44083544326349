"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {
    onEnterViewPort($scope.find('.js-render-template'), function () {
       renderTemplate($(this));
    }, { offset: 300 });

}

export function renderTemplate(renderTemplate) {
    let $template = renderTemplate.find('.js-render-template__template');
    let templateHtml = $template.html();

    renderTemplate.html(templateHtml).removeClass('js-render-template');
    $template.remove();
    initModulesInScope(renderTemplate);
}

export function renderAllTemplates() {
    $('.js-render-template').each(function() {
        renderTemplate($(this));
    })
}