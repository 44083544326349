"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

const defaultSelectors = {
    childInputs: '.js-quickfinder__child-input',
    childContainer: '.js-quickfinder__childs'
};

export function createInitInScope(selector, selectors = {}, template) {
    return function ($scope) {
        selectors = {
            ...defaultSelectors,
            ...selectors
        };

        let $quickfinder = $scope.find(selector);

        $quickfinder.each(function () {
            let $quickfinder = $(this);

            $quickfinder.find(selectors.childInputs).on('change', function () {
                if($(this).val() > 0) {
                    $quickfinder.find(selectors.childContainer).empty();

                    let childGroup = [];
                    for (let i = 1; i <= $(this).val(); i++) {
                        childGroup.push({id: i, name: 'room-0-child-'});
                    }

                    $quickfinder.append(template(childGroup));
                    initModulesInScope($quickfinder.find(selectors.childContainer));
                } else {
                    $quickfinder.find(selectors.childContainer).remove();
                }
            });
        });

        return $quickfinder;
    }
}

export const initInScope = createInitInScope();