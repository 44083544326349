"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope ($scope) {
    let $viewportElement = $scope.find('.js-is-in-viewport');

    $viewportElement.each(function() {
        let settings = {
            offset: $(this).attr('data-viewport-offset') || window.innerHeight / 2
        };

        onEnterViewPort($(this), function () {
            $(this).addClass('is-in-viewport');
        }, settings);
    });
}