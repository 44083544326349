"use strict";

const isMobile = () => matchMedia('(max-width: 767px)').matches;
let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;
let defaultTopPosition = 0;
let isAffix = false;
let isDefault = true;

export function initInScope($scope) {
    $navbarAffix = $('.js-affix-nav-bar');
    updateDefaultTopPosition();

    if ($navbarAffix) {
        navbarToggleAffix($navbarAffix);
    }
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    updateDom();
    lastScrollPosition = scrollTopPosition;
    requestAnimationFrame(interval);
}

export function updateDom() {
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = lastScrollPosition > scrollTopPosition && lastScrollPosition > defaultTopPosition;
    $navbarAffix.isDefault = scrollTopPosition <= defaultTopPosition;

    if ($navbarAffix.isAffix) {
        if($navbarAffix.isDefault) {
            defaultAffix();
            isAffix = false;
            isDefault = true;
        } else if(!isAffix){

            setAffix();
            isAffix = true;
            isDefault = false;
        }
    } else {
        if($navbarAffix.isDefault) {
            defaultAffix();
            isDefault = true;
            isAffix = false;
        } else if(isAffix || isDefault){

            unsetAffix();
            isAffix = false;
            isDefault = false;
        }
    }
}
 
export function setAffix() {
    $navbarAffix.addClass('is-affix');
    $navbarAffix.removeClass('not-is-affix');
    updateDefaultTopPosition();
}

export function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
    $navbarAffix.addClass('not-is-affix');
    updateDefaultTopPosition();
}

export function defaultAffix() {
    $navbarAffix.removeClass('is-affix');
    $navbarAffix.removeClass('not-is-affix');
    updateDefaultTopPosition();
}

export function updateDefaultTopPosition() {
    if($('.js-affix-nav-bar__placeholder').length > 0) {
        defaultTopPosition =  $('.js-affix-nav-bar__placeholder').offset().top || 0;
    }
}

