"use strict";

//iframe parent
export function init() {
    let iframes = document.querySelectorAll('.js-tosc-iframe-resizer');

    window.addEventListener('message', function (msg, ...rest) {
        if (typeof msg.data === 'object' && msg.data.height) {
            iframes.forEach(function (iframe) {
                if (iframe.getAttribute('src').indexOf(msg.origin) >= 0) {
                    iframe.style.height = msg.data.height + 'px';
                    iframe.setAttribute('scrolling', 'no');
                }

            })
        }
    }, false);

    iframes.forEach(function (iframe) {
        iframe.contentWindow.postMessage('getHeight', 'https://web4.deskline.net');
        iframe.addEventListener('load', function () {
            // it's important to set the targetOrigin
            //TODO: set targetOrigin!!
            iframe.contentWindow.postMessage('getHeight', 'https://web4.deskline.net');
            console.log("here");
        });
    });
}
