"use strict";


let sliderId = 0;

export function initInScope($scope) {
    let $slider = $scope.find('.js-thumb-slider');

    import('slick-carousel').then(function() {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-thumb-slider__main');
            let $thumbSlider = $container.find('.js-thumb-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'main-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

            $mainSlider.slick({
                asNavFor: '#thumb-slider__thumb-' + id,
                dots: true,
                arrows:false,
                slidesToShow:1,
                infinite: false,
                fade: false,
                cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
                speed:600,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });

            if($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    arrows:true,
                    prevArrow: '<button type="button" class="thumb-slider__arrow arrow-prev" aria-label="Prev" title="Prev"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>\n',
                    nextArrow: '<button type="button" class="thumb-slider__arrow arrow-next" aria-label="Next" title="Next"><span class="icon icon-arrow-left icon-rotate-180"></span></button>\n',
                    infinite: false,
                    centerMode: false,
                    slidesToShow: 10,
                    slidesToScroll:1,
                    variableWidth: true,
                    swipeToSlide: true,
                    asNavFor: '#main-slider__main-' + id,
                    focusOnSelect: true,
                    responsive: [{
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 4,
                            centerMode:false,
                            variableWidth: false,
                            infinite: true //needed to show images in properly sized (if you delete this line, images are too small)
                        }
                    }]
                })
            }
        });
    });
}

