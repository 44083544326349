"use strict";

import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope ($scope) {

    $scope.find('.js-toggle-subnav-dropdown').on('click', function (e) {
        let $thisLi = $(this);
        let $nav = $('.js-nav');

        if(!$(this).is('li')){
            $thisLi = $(this).closest('li');
        }

        if($thisLi.hasClass('is-open')){
            $thisLi.removeClass('is-open');
            $nav.removeClass('dropdown-is-open');
            $('body').on('click', bodyClickHandler);
        }else{
            $nav.addClass('dropdown-is-open');
            $thisLi.addClass('is-open');
            $('body').on('click', bodyClickHandler);
        }

        let $renderNavbarContainer = $('.js-render-navbar-template');

        if($renderNavbarContainer.length){

            $renderNavbarContainer.each(function (index, element) {
                let $template = $(this).find('.js-render-navbar-template__template');
                let templateHtml = $template.html();

                $(this).html(templateHtml).removeClass('js-render-navbar-template');

                $template.remove();
                initModulesInScope($(this));
            });

        }

    });
}

function bodyClickHandler (evt) {
    let $target = $(evt.target);
    let $parent = $target.closest('.nav-dropdown, .js-toggle-subnav-dropdown, #ui-datepicker-div');
    let $nav = $('.js-nav');

    if (!$target.is('.nav-dropdown, .js-toggle-subnav-dropdown, .ui-datepicker-prev, .ui-datepicker-next') && !$parent.length) {
        evt.preventDefault();

        let $toggleNav = $('.js-toggle-subnav-dropdown');
        if($toggleNav.is('li')){
            $toggleNav.removeClass('is-open');
        }else{
            $toggleNav.closest('li').removeClass('is-open');
        }
        $nav.removeClass('dropdown-is-open');
        $('body').off('click', bodyClickHandler);
    }
}