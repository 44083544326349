"use strict";

import {trackEvent} from "@elements/tracking/src/gtm";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {isValid} from "@elements/parsley-bootstrap-validation";

export function initInScope($scope) {
    $scope.find('.js-gtm-tracking--form').on('submit', function() {
        let $form = $(this);
        let data = {};
        data = {
            ...data,
            ...getPrefixedDataSet('gtm-tracking', $form)
        };


        if($form.hasClass('js-parsley')) {
            if(isValid($form)) {
                trackEvent(data, {debug: true});
            }
        } else {
            trackEvent(data, {debug: true});
        }
    });
}