"use strict";
import loadMapsAPI from './loadMapsAPI';

export function initInScope($scope) {
    function initOA( oamaps, $map) {
        let layerConfig = (oa.PREFIX === 'oam') ? null : {
            markersActive : true,
            defaultIW : true
        };

        // initialize GeomLayer
        let layer = new oamaps.GeomLayer( _config.outdoorActiveMapId, layerConfig );

        layer.whenLoaded( function () {
            let mapTypeIds = ['oa_map', 'oa_topo', 'oa_map_winter' ];

            let mapConfig = {
                bounds: layer.getBounds(),
                mapTypeId : mapTypeIds[ 0 ],
                mapTypeControlOptions : { mapTypeIds: mapTypeIds }
            };

            let map = oamaps.map( $map[0], mapConfig );

            // add GeomLayer to map
            layer.setMap( map );
        });

    }

    let mapElements = $scope.find('.js-tour-map');
    loadMapsAPI().then(() => {
        if (mapElements && mapElements.length) {
            oax.api.maps(function (maps) {
                // alpsteinMap = maps;

                mapElements.each(function (index) {
                    initOA(maps, $(this).find('.js-tour-map__canvas'));
                });
            });
        }
    });
}
