"use strict";

export function initInScope ($scope) {
    let $countdown = $scope.find('.js-countdown');

    import('countdown-js').then(function (Countdown) {
        $countdown.each(function() {
            let $this = $(this);
            let $countdownDays = $this.find('.js-countdown__days');
            let $countdownHours = $this.find('.js-countdown__hours');
            let $countdownMins = $this.find('.js-countdown__mins');
            let $countdownSecs = $this.find('.js-countdown__secs');

            let timestamp = new Date($(this).attr('data-countdown-time'));
            let countdown = Countdown.default.timer(timestamp, function(time) {
                $countdownDays.html(time.days);
                $countdownHours.html(time.hours);
                $countdownMins.html(time.minutes);
                $countdownSecs.html(time.seconds);
            });
        });
    });
}
