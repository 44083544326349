"use strict";


let sliderId = 0;

export function initInScope($scope) {
    let $slider = $scope.find('.js-webcam-slider');

    import('slick-carousel').then(function() {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-webcam-slider__main');
            let $thumbSlider = $container.find('.js-webcam-slider__thumbs');
            let id = sliderId++;

            const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
            const $collapseParent = $slider.closest('.collapse');
            const $modalParent = $slider.closest('.modal');

            $mainSlider.attr('id', 'main-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumbs-' + id);

            if ($tabPaneParent && $tabPaneParent.length) {
                /*wait for the initializiation of the slider if it is hidden in a tab */
                const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

                $tabTrigger.one('shown.bs.tab', function () {
                    initSlider($mainSlider, $thumbSlider, id);
                });

            } else if ($collapseParent && $collapseParent.length) {
                /* wait until slide is shown if it is initially hidden in a collapse/accordion */
                $collapseParent.one('shown.bs.collapse', function () {
                    initSlider($mainSlider, $thumbSlider, id);
                });

            } else if ($modalParent && $modalParent.length) {
                /* wait until slide is shown if it is initially  hidden in a modal */
                $modalParent.one('shown.bs.modal', function () {
                    initSlider($mainSlider, $thumbSlider, id);
                });

            } else {
                // no tab, accordion, modal -> init slider as usual
                initSlider($mainSlider, $thumbSlider, id);
            }

        });
    });

    function initSlider($mainSlider, $thumbSlider, id) {
        $mainSlider.slick({
            asNavFor: '#thumb-slider__thumbs-' + id,
            dots: true,
            prevArrow: '<button type="button" class="webcam-slider__arrow arrow-prev" aria-label="Prev" title="Prev"><span class="icon icon-arrow-left" aria-hidden="true"></span></button>\n',
            nextArrow: '<button type="button" class="webcam-slider__arrow arrow-next" aria-label="Next" title="Next"><span class="icon icon-arrow-left icon-rotate-180"></span></button>\n',
            slidesToShow:1,
            infinite: false,
            fade: false,
            cssEase: 'cubic-bezier(0.4, 0, 0.3, 1)',
            speed:600,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    asNavFor: null
                }
            }]
        });

        if (matchMedia('(min-width: 768px)').matches) {
            if ($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 4,
                    infinite: false,
                    swipeToSlide: true,
                    asNavFor: '#main-slider__main-' + id,
                    focusOnSelect: true
                })
            }
        }
    }
}

