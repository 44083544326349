"use strict";

import * as Chartist from "chartist";
import ChartistTooltipPlugin from "chartist-plugin-tooltip";
import {requireConfigs, getConfig} from "@elements/config-utils";
import fetch from '@elements/fetch';

export function initInScope($scope) {

    const $element = $scope.find('.js-weather-forecast');

    $element.each(function() {
        let $this = $(this);
        let $tabParent = $this.closest('.tab-pane');

        initChart($this);

        if ($tabParent && $tabParent.length) {
            let id = $tabParent.attr('id');
            let $tabTrigger = $('[data-toggle="tab"][href="#' + id + '"], [data-toggle="tab"][data-target="#' + id + '"]');

            $tabTrigger.on('shown.bs.tab', function () {
                initChart($this)
            });

            return;
        }

    });

}

function initChart($element) {
    let url = $element.data('weather-forecast-url');
    let pendingRequest = fetch(url);

    pendingRequest.then((data) => {
        return data.clone().json()
    }).then((data) => {
        drawGraph($element[0], data);
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            console.error(error);
        }
    });
}

function drawGraph(domNode, data) {
    let options = {
        fullWidth: true,
        height: $('.weather-forecast__canvas-wrapper').height() - 50,
        lineSmooth: false,
        showArea: false,
        axisY: {
            offset: 0,
            showLabel: false
        },
        axisX: {
            labelOffset: {
                x: 0,
                y: 0
            },
            offset: 0,
            position: 'start',
            showLabel: false
        },
        chartPadding: {
            top: 20,
            right: 10,
            bottom: 30,
            left: 10
        },
        plugins: [
            ChartistTooltipPlugin()
        ]
    };

    let chart = new Chartist.Line(domNode, data, options);

    chart.on('draw', function (data) {
        if (data.type === 'point') {
            let radius =  matchMedia('(max-width: 767px)').matches ? '6' : '9';
            let circle = new Chartist.Svg('circle', {
                cx: [data.x],
                cy: [data.y],
                'ct:value': [data.series[data.index]],
                r: [radius],
            }, 'ct-circle ct-point');
            data.element.replace(circle);
        }
        if (data.type === 'line' || data.type === 'area') {
            data.element.animate({
                d: {
                    begin: 50 * data.index,
                    dur: 300 * Math.pow(.9, data.index),
                    from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
                    to: data.path.clone().stringify(),
                    easing: Chartist.Svg.Easing.easeOutQuint
                }
            });
        }
    });

    return data;
}

function isUrl(url) {
    return url && typeof url === 'string' && (url.includes('/') || url.includes('#'));
}
