"use strict";

export function initInScope($scope) {
    try{
        if(!window.sessionStorage.getItem('finishRegistrationModal')) {
            $scope.find('#modalFinishRegistration').modal('show');
        }

        $scope.find('#modalFinishRegistration').on('hidden.bs.modal', function (e) {
            window.sessionStorage.setItem('finishRegistrationModal', true);
        });

        $scope.find('.js-close-finish-registration-modal').on('click', function (e) {
            $('#modalFinishRegistration').modal('hide');
            window.sessionStorage.setItem('finishRegistrationModal', true);
        });
    }catch(e){
        console.log(e);
    }
}
