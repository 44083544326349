"use strict";

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function initInScope($scope) {
    $scope.find('.js-expandable').each(function () {

        let $expandableElement = $(this);
        let onlyExpandMobile = $expandableElement.data('only-mobile') || false;

        if(onlyExpandMobile && !isMobile) {
            return;
        }

        let $tabParent = $expandableElement.closest('.tab-pane');

        // the timeout is a hack to make it work in ie and ff - so the css should be there at this point
        let timeoutCounter = 0;
        let initTimeout = function() {
            timeoutCounter++;
            if (timeoutCounter < 7) {
                // don't do it if it has is-expandable but not is-collapsed
                if ($expandableElement.hasClass('is-expandable') && !$expandableElement.hasClass('is-collapsed')) {
                    return;
                }
                if ($expandableElement.hasClass('is-expandable') && $expandableElement.hasClass('is-collapsed')) {
                    $expandableElement.removeClass('is-expandable').removeClass('is-collapsed');
                }
                initCollapseElement($expandableElement);
                setTimeout(initTimeout, 300);
            }
        };
        initTimeout();

        if ($tabParent && $tabParent.length && !$tabParent.hasClass('active')) {
            let id = $tabParent.attr('id');
            let $tabTrigger = $('[data-toggle="tab"][href="#' + id + '"], [data-toggle="tab"][data-target="#' + id + '"]');

            $tabTrigger.on('shown.bs.tab', function () {
                initCollapseElement($expandableElement)
            });

            return;
        }

        let $collapseParents = $expandableElement.parents('.collapse');
        if ($collapseParents && $collapseParents.length) {
            $collapseParents.on('shown.bs.collapse', function () {
                initCollapseElement($expandableElement)
            });

            return;
        }
    });
}

export function initCollapseElement($expandableElement) {
    let expandableContent = $expandableElement.find('.js-expandable__content-wrapper');
    if(expandableContent.length > 0 && expandableContent[0] !== 'undefined') {

        if (expandableContent[0].scrollHeight > expandableContent.outerHeight()  + 100) {
            $expandableElement.removeClass('is-not-expandable');
            $expandableElement.addClass('is-expandable is-collapsed');

            $expandableElement.find('.js-expandable__more').on('click', function (evt) {
                evt.preventDefault();
                $expandableElement.removeClass('is-collapsed');

            }).on('click', function (evt) {
                evt.preventDefault();
            });
        } else if (expandableContent[0].scrollHeight != 0) { // if display none
            $expandableElement.addClass('is-not-expandable').removeClass('is-collapsed');
        }
    }

}
