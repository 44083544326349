"use strict";

export function initInScope ($scope) {
    let buddySticky = $scope.find('.js-buddy-sticky-badge');
    let closeBtn = $scope.find('.js-buddy-sticky-badge__close-btn');
    let revealTime = $scope.find('.js-buddy-sticky-badge').data('reveal-time');

    setTimeout(function(){
        buddySticky.addClass("is-visible");
    }, revealTime);

    closeBtn.on('click', function () {
       buddySticky.removeClass('is-visible');
    });
}
