export function init () {
    $(window).on('CookiebotOnConsentReady', function () {
        if (Cookiebot.consent['marketing']) {
            let accessiwayLeadColor = "#e10632";
            let accessiwayTriggerColor = "#e10632";
            let accessiwayDisableBgProcess = false;

            if (_config.accessiwayLeadColor) {
                accessiwayLeadColor = _config.accessiwayLeadColor;
            }
            if (_config. accessiwayTriggerColor) {
                accessiwayTriggerColor = _config.accessiwayTriggerColor;
            }
            if (_config.accessiwayDisableBgProcess) {
                accessiwayDisableBgProcess = _config.accessiwayDisableBgProcess;
            }

            (function(){
                var s = document.createElement('script'),
                    e = !document.body ? document.querySelector('head') :
                        document.body;
                s.src = 'https://eu.acsbapp.com/apps/app/dist/js/app.js';
                s.async = true;
                s.onload = function(){
                    acsbJS.init({
                        statementLink : '',
                        footerHtml :  '<a  href="https://www.accessiway.com/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
                        hideMobile : false,
                        hideTrigger : false,
                        disableBgProcess: accessiwayDisableBgProcess,
                        language : 'de',
                        position : 'right',
                        leadColor : accessiwayLeadColor,
                        triggerColor : accessiwayTriggerColor,
                        triggerRadius : '0',
                        triggerPositionX : 'right',
                        triggerPositionY : 'bottom',
                        triggerIcon : 'people',
                        triggerSize : 'medium',
                        triggerOffsetX : 0,
                        triggerOffsetY : 150,
                        mobile : {
                            triggerSize : 'small',
                            triggerPositionX : 'right',
                            triggerPositionY : 'center',
                            triggerOffsetX : 0,
                            triggerOffsetY : 10,
                            triggerRadius : '0'
                        }
                    });
                };
                e.appendChild(s);

                if (accessiwayDisableBgProcess) {
                    console.log("AccessiWay: Disable background process 1");
                    var observer = new MutationObserver(function (mutations, mutationInstance) {
                        if (
                            document.getElementsByClassName("acsb-profile acsb-profile-motor")[0]
                        ) {
                            document.getElementsByClassName("acsb-profile acsb-profile-motor")[0].remove();
                            document.getElementsByClassName("acsb-profile acsb-profile-blind")[0].remove();
                            mutationInstance.disconnect();
                        }
                    });
                    observer.observe(document, {
                        childList: true,
                        subtree: true,
                    });
                }
            }());

            if (accessiwayDisableBgProcess) {
                console.log("AccessiWay: Disable background process 2");
                const observer = new MutationObserver((mutationsList, observer) => {
                    for(let mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            const awInterface = document.getElementsByTagName("access-widget-ui")[2];
                            if (awInterface && awInterface.shadowRoot) {
                                const motorElement = awInterface.shadowRoot.querySelector('[data-test="motor"]');
                                const blindElement = awInterface.shadowRoot.querySelector('[data-test="blind"]');

                                if (motorElement) motorElement.remove();
                                if (blindElement) blindElement.remove();

                                if (motorElement || blindElement) {
                                    observer.disconnect();
                                }
                            }
                        }
                    }
                });

                observer.observe(document.body, { childList: true, subtree: true });
            }
        }
    })
}