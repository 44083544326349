"use strict";

export function initInScope($scope) {
    let $ajaxForm = $('.js-ajax-form');
    $ajaxForm.on('submit.ajax-form', function() {
        let $wrapper = $(this);
        scrollToResult($wrapper);
    });

    let $ajaxLink = $('.js-ajax-form__link');
    $ajaxLink.on('click', function() {
        let $wrapper = $(this).closest('.js-ajax-form');
        scrollToResult($wrapper);
    })
}

function scrollToResult($wrapper) {
    let offset = matchMedia('(max-width: 767px)').matches ? 80 : 200;
    let $target = $wrapper.find('.js-ajax-form__result');
    scrollTo($target, offset, function () {
        focus($target);
    });
}

export function scrollTo ($target, offset = 0) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 500;


    let newOffset = $target.offset().top - offset;

    $('html, body').stop().animate({
        scrollTop: newOffset
    }, duration, 'swing');
}

export function focus ($target) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}
