"use strict";

import initModulesInScope from '@elements/init-modules-in-scope';

let alreadyHidden;

export function initInScope($scope) {

    if (matchMedia('(max-width: 767px)').matches) {

        $scope.find('.js-nav__toggle').on('click', function (e) {
            let $navToggle = $(this),
                $navTarget = $($navToggle.data('target'));

            $('.js-nav__toggle').not(this).removeClass('is-open');
            $('.js-navbar-overlay-menu').not($navTarget).removeClass('is-open');
            let topPosition = $('.js-affix-nav-bar')[0].getBoundingClientRect().top + $('.js-affix-nav-bar')[0].getBoundingClientRect().height;
            $('.js-navbar-overlay-menu').css('top', topPosition);

            if($navToggle.hasClass('is-open')){
                $navToggle.removeClass('is-open');
                $navTarget.removeClass('is-open');
                $('body').removeClass('nav-open');
            }else{
                $navToggle.addClass('is-open');
                $navTarget.addClass('is-open');
                $('body').addClass('nav-open');
            }

            let $renderNavbarContainer = $('.js-render-navbar-template');

            if($renderNavbarContainer.length){

                $renderNavbarContainer.each(function (index, element) {
                    let $template = $(this).find('.js-render-navbar-template__template');
                    let templateHtml = $template.html();

                    $(this).html(templateHtml).removeClass('js-render-navbar-template');

                    $template.remove();
                    initModulesInScope($(this));
                });

            }
        });


        $scope.find('.js-nav__link').on('click', function (evt) {
            let $currentLink = $(this);
            let $nav = $currentLink.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            let $navItem = $currentLink.closest('.js-nav__item');

            evt.preventDefault();

            if($navItem.hasClass('active')) {
                $navItem.removeClass('active');
            } else {
                $navItem.addClass('active');
            }
        });


        $scope.find('.js-nav__go-back').on('click', function(evt) {
            evt.preventDefault();

            // let levelTwoItems = $('.nav-item__level-2');
            let levelOneItems = $('.nav-item__level-1');
            let levelZeroItems = $('.nav-item__level-0');
            alreadyHidden = false;

            // checkLevelToGoBack(levelTwoItems);
            checkLevelToGoBack(levelOneItems);
            checkLevelToGoBack(levelZeroItems);
        });
    }
}

//ios fix
function checkLevelToGoBack(level) {
    if(!alreadyHidden) {
        level.each(function() {
            let $item = $(this);
            if($item.hasClass('active')) {
                $item.removeClass('active');
                alreadyHidden = true;
            }
        });
    }
}