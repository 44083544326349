"use strict";
import {updateDefaultTopPosition} from "./affix-navbar";
import {updateFullHeight} from "./fullHeight";

export function initInScope ($scope) {
    let $notificationHeader = $scope.find('.js-notification-header');

    try {
        let data = sessionStorage.getItem('notificationHeader');
        if (data != 'alreadyShown') {
            $notificationHeader.removeAttr('hidden');
            updateDefaultTopPosition();
            updateFullHeight();
        }
    } catch(err) {}

    $('.js-notification-header__close, .js-notification-header__link').on('click', function() {
        try {
            sessionStorage.setItem('notificationHeader', 'alreadyShown');
            $notificationHeader.attr('hidden', '');
            updateDefaultTopPosition();
            updateFullHeight();
        } catch(err) {}
    });
}

