"use strict";


export function initInScope($scope) {
    let $sliders = $scope.find('.js-nav-info-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {

            let $slider = $(this);

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:false,
                swipeToSlide: false,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                infinite:true,
                speed: 1,
                cssEase: 'ease'
            });
        });
    })
}